import React from 'react';
import {render} from 'react-dom';
//import './css/semantic.css';

import './css/components/reset.css'
import './css/components/site.css'
import './css/components/sidebar.css';
import './css/components/header.css'
import './css/components/segment.css';
import './css/components/grid.css';
import './css/components/container.css';
import './css/components/menu.css';
import './css/components/dropdown.css';
import './css/components/input.css';
import './css/components/form.css';
import './css/components/divider.css';
import './css/components/icon.css';
import './css/components/popup.css';
import './css/components/label.css';
import './css/components/button.css';
import './css/components/message.css';
import './css/components/item.css';
import './css/components/list.css';
import './css/components/image.css';
import './css/components/dimmer.css';
//import './css/components/transition.css';

//import 'semantic-ui-css/semantic.min.css'
import './index.css';
import FullApp from './FullApp';
//import * as serviceWorker from './serviceWorker';

render(<FullApp/>, document.getElementById('root'));
//serviceWorker.unregister();