import {FETCH_ASKS} from '../actions/index';

export default function (state = null, action) {

  switch (action.type) {
    case FETCH_ASKS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}
