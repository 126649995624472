import {FETCH_BIDS} from '../actions/index';

export default function (state = null, action) {

  switch (action.type) {
    case FETCH_BIDS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}
