import querystring from 'querystring';
import axios from 'axios';
import { navigateTo, getCurrentLanguage } from '../helpers/languagerouter';

export const ROOT_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://apitest.inpay.pl';

export const LOGIN = 'LOGIN';
export const USER_GET = 'USER_GET';
export const USER_DELETE = 'USER_DELETE';
export const FETCH_BIDS = 'FETCH_BIDS';
export const FETCH_ASKS = 'FETCH_ASKS';
export const BID_CALCULATE = 'BID_CALCULATE';
export const CREATE_PURCHASE = 'CREATE_PURCHASE';
export const PURCHASE_STATUS = 'PURCHASE_STATUS';
export const PURCHASE_DECISION = 'PURCHASE_DECISION';
export const CREATE_TRANSFER = 'CREATE_TRANSFER';
export const CREATE_TRANSFER_ATM = 'CREATE_TRANSFER_ATM';
export const ASK_CALCULATE = 'ASK_CALCULATE';
export const TRANSFER_STATUS = 'TRANSFER_STATUS';
export const PARTNER_GET = 'PARTNER_GET';
export const PARTNER_UPDATE = 'PARTNER_UPDATE';
export const PASSWORD_UPDATE = 'PASSWORD_UPDATE';
export const USER_BALANCES = 'USER_BALANCES';
export const USER_INVOICES = 'USER_INVOICES';
export const USER_CALLBACKS = 'USER_CALLBACKS';
export const USER_CALLBACK = 'USER_CALLBACK';
export const USER_API_KEYS = 'USER_API_KEYS';
export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const PAYMENT_STATUS = 'PAYMENT_STATUS';
export const SINGLE_INVOICE = 'SINGLE_INVOICE';
export const INVOICE_STATUS = 'INVOICE_STATUS';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_WITHDRAWALS = 'USER_WITHDRAWALS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_PASSWORD = 'SET_PASSWORD';
export const USER_REGISTER = 'USER_REGISTER';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const GET_INVOICE = 'GET_INVOICE';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const USER_API_KEY_LIST = 'USER_API_KEY_LIST';
export const USER_API_KEY_CREATE = 'USER_API_KEY_CREATE';
export const USER_API_KEY_DEACTIVATE = 'USER_API_KEY_DEACTIVATE';
export const CREATE_PAYMENT_LINK = 'CREATE_PAYMENT_LINK';
export const RATES_HISTORY = 'RATES_HISTORY';
export const ACCOUNT_STATEMENT = 'ACCOUNT_STATEMENT';
export const ACCOUNT_STATEMENT_CHECK = 'ACCOUNT_STATEMENT_CHECK';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const GENERAL_ERROR = 'GENERAL_ERROR';
export const LOGIN_REQUIRED = 'LOGIN_REQUIRED';
export const NETWORK_PROBLEM = 'NETWORK_PROBLEM';
export const GET_USER_BY_HASH = 'GET_USER_BY_HASH';
export const CHANGE_STATUS = 'CHANGE_STATUS';
export const GET_OTHER_TRANSACTIONS = 'GET_OTHER_TRANSACTIONS';
export const SUPPORT_PURCHASE_STATUS = 'SUPPORT_PURCHASE_STATUS';
export const PURCHASE_LIMITS = 'PURCHASE_LIMITS';
export const SEND_SELFIE = 'SEND_SELFIE';
export const REQUEST_LIST = 'REQUEST_LIST';
export const USER_PURCHASES = 'USER_PURCHASES';
export const USER_TRANSFERS = 'USER_TRANSFERS';
export const GET_KYC_LIST = 'GET_KYC_LIST';
export const KYC_STATUS_CHANGE = 'KYC_STATUS_CHANGE';
export const VERIFICATION_STATUSES = 'VERIFICATION_STATUSES';
export const USERS_LIST = 'USERS_LIST';
export const LOGIN_TO_USER = 'LOGIN_TO_USER';
export const BUY_TOKENS = 'BUY_TOKENS';
export const GET_TFA_CODE = 'GET_TFA_CODE';
export const GET_TFA_VERIFY = 'GET_TFA_VERIFY';
export const DEACTIVATE_TFA = 'DEACTIVATE_TFA';
export const TOKEN_ASK_CALCULATE = 'TOKEN_ASK_CALCULATE';
export const COUNTRY_LIST = 'COUNTRY_LIST';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const CREATE_TERMINAL_PAYMENT = 'CREATE_TERMINAL_PAYMENT';
export const TERMINAL_INVOICE_STATUS = 'TERMINAL_INVOICE_STATUS';
export const GET_LATEST_TRANSACTIONS = 'GET_LATEST_TRANSACTIONS';
export const GET_DEVELOPMENT_NEWS = 'GET_DEVELOPMENT_NEWS';
export const CHANGE_INVOICE_CRYPTO = 'CHANGE_INVOICE_CRYPTO';
export const VOUCHER_CREATE = 'VOUCHER_CREATE';
export const VOUCHER_STATUS = 'VOUCHER_STATUS';
export const VOUCHER_WITHDRAW = 'VOUCHER_WITHDRAW';
export const VOUCHER_VALIDATE_WITHDRAW = 'VOUCHER_VALIDATE_WITHDRAW';
export const VALIDATE_PIN_WITHDRAW = 'VALIDATE_PIN_WITHDRAW';
export const KYC_STATUS = 'KYC_STATUS';
export const ACCEPT_MIGRATION = 'ACCEPT_MIGRATION';
export const ACCEPT_TERMS = 'ACCEPT_TERMS';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const KYC_UPDATE_OPTIONAL = 'KYC_UPDATE_OPTIONAL';
export const KYC_RESET = 'KYC_RESET';
export const KYC_ADD_EXPIRATION_DATE = 'KYC_ADD_EXPIRATION_DATE';
export const RUSHPAY_PAYMENT = 'RUSHPAY_PAYMENT';
export const FIBERPAY_PAYMENT = 'FIBERPAY_PAYMENT';
export const CREATE_VERIFICATION_ORDER = 'CREATE_VERIFICATION_ORDER';
export const USER_CHECK_PASSWORD = 'USER_CHECK_PASSWORD';
export const CONFIRM_VIDEOVERIFICATION = 'CONFIRM_VIDEOVERIFICATION';
export const GET_COMMISSIONS_TABLE = 'GET_COMMISSIONS_TABLE';
export const GET_VERIFIED_BANK_ACCOUNTS = 'GET_VERIFIED_BANK_ACCOUNTS';
export const CREATE_BANK_ACCOUNTS_VERIFICATION_ORDER = 'CREATE_BANK_ACCOUNTS_VERIFICATION_ORDER';
export const GET_BANK_ACCOUNTS_VERIFICATION_ORDERS = 'GET_BANK_ACCOUNTS_VERIFICATION_ORDERS';

export const CAPTCHA_SITEKEY = () => {
  if(process.env.REACT_APP_CAPTCHA_KEY)
    return process.env.REACT_APP_CAPTCHA_KEY;
  switch (ROOT_URL) {
    case 'https://apitest.inpay.pl':
      return '6Lf9yWsUAAAAAETooGWZunwH2ldCzdqrxdbv9Ahc';
    case 'https://api.inpay.pl':
    case 'https://api.swap.ly':
      return '6LecU28UAAAAAMxSWHvEHmXu4ZMba83Aq-3s94hn';
    case 'https://apitest.swap.ly':
      return '6LcncaAUAAAAAG6Sk-foItsVEFAXbn0_PnWv-Zhx';
    case 'https://testywew.beesfund.market/':
      return '6Ldx2aIUAAAAAPhnUyIWmmZTASAINNtxNgchGmsP';
    default:
      break;
  }
};

export const TERMS_SELL_FILE = ROOT_URL+ '/alias/sell-terms';
export const TERMS_BUY_FILE = '/files/swaply/2019-12-17-regulamin-sprzedazy-kryptowalut.pdf';


export function loginUser(formData) {
  const url = `${ROOT_URL}/user/login`;
  const request = axios.post(
    url,
    querystring.stringify(formData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Inpays-Site-Language" : getCurrentLanguage()

      },
      "withCredentials": true
    }
  );
  return {
    type: LOGIN,
    payload: request
  };
}

export function fetchUser(formData) {
  const url = `${ROOT_URL}/user/get`;
  return generalCall(formData, url, USER_GET);
}

export function userDelete(formData) {
  const url = `${ROOT_URL}/user/delete`;
  return generalCall(formData, url, USER_DELETE);
}

export function fetchUserWithoutRedirect() {
  const url = `${ROOT_URL}/user/get`;
  const request = axios.get(url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }, "withCredentials": true
  });
  return {
    type: USER_GET,
    payload: request
  };
}

export function getUserByHash(formData) {
  const url = `${ROOT_URL}/user/getbyhash`;
  return generalCall(formData, url, GET_USER_BY_HASH);
}

export function setPassword(formData) {
  const url = `${ROOT_URL}/user/resetpassword`;
  return generalCall(formData, url, SET_PASSWORD);
}

//TO CHECK
export function purchaseLimits() {
  const url = `${ROOT_URL}/purchase/limits`;
  const request = axios.get(url, {
    headers: {
      "Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1"
    }, "withCredentials": true
  });
  return {
    type: PURCHASE_LIMITS,
    payload: request
  };
}

export function fetchBids(formData) {
  const url = `${ROOT_URL}/rates/exchangesbids`;
  const request = axios.post(
    url,
    querystring.stringify(formData), {
      headers: {
        "Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1"
      }, "withCredentials": true
    });
  return {
    type: FETCH_BIDS,
    payload: request
  };
}

export function fetchAsks(formData) {
  const url = `${ROOT_URL}/rates/exchangesasks`;
  const request = axios.post(
    url,
    querystring.stringify(formData), {
      headers: {
        "Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1"
      }, "withCredentials": true
    });
  return {
    type: FETCH_ASKS,
    payload: request
  };
}

export function bidCalculate(formData) {
  const url = `${ROOT_URL}/rates/bidcalculate`;
  return generalCall(formData, url, BID_CALCULATE);
}

export function createPurchase(formData) {
  const url = `${ROOT_URL}/purchase/create`;
  return generalCall(formData, url, CREATE_PURCHASE);
}

export function purchaseStatus(formData) {
  const url = `${ROOT_URL}/purchase/status`;
  return generalCall(formData, url, PURCHASE_STATUS);
}

export function purchaseDecision(formData) {
  const url = `${ROOT_URL}/purchase/decision`;
  return generalCall(formData, url, PURCHASE_DECISION);
}

export function askCalculate(formData) {
  const url = `${ROOT_URL}/rates/askcalculate`;
  return generalCall(formData, url, ASK_CALCULATE);
}

export function createTransfer(formData) {
  const url = `${ROOT_URL}/transfer/create`;
  return generalCall(formData, url, CREATE_TRANSFER);
}

// export function createTransferAtm(formData) {
//   const url = `${ROOT_URL}/transfer/createatmwithdrawal`;
//   return generalCall(formData, url, CREATE_TRANSFER_ATM);
// }

export function transferStatus(formData) {
  const url = `${ROOT_URL}/transfer/status`;
  return generalCall(formData, url, TRANSFER_STATUS);
}

export function partnerGet(formData) {
  const url = `${ROOT_URL}/partner/get2`;
  return generalCall(formData, url, PARTNER_GET);
}

export function partnerUpdate(formData) {
  const url = `${ROOT_URL}/partner/update`;
  return generalCall(formData, url, PARTNER_UPDATE);
}

export function updatePassword(formData) {
  const url = `${ROOT_URL}/user/updatepassword`;
  return generalCall(formData, url, PASSWORD_UPDATE);
}

// export function balanceGet(formData) {
//   const url = `${ROOT_URL}/user/balances`;
//   return generalCall(formData, url, USER_BALANCES);
// }

export function showInvoices(formData) {
  const url = `${ROOT_URL}/invoice/userinvoices`;
  return generalCall(formData, url, USER_INVOICES);
}

export function changeStatus(formData) {
    const url = `${ROOT_URL}/support/changestatusofpurchase`;
    return generalCall(formData, url, CHANGE_STATUS);
}

export function getOtherTransactions(formData) {
    const url = `${ROOT_URL}/support/getothertransactions`;
    return generalCall(formData, url, GET_OTHER_TRANSACTIONS);
}

export function supportPurchaseStatus(formData) {
    const url = `${ROOT_URL}/support/purchasestatus`;
    return generalCall(formData, url, SUPPORT_PURCHASE_STATUS);
}

export function showCallbacks(formData) {
  const url = `${ROOT_URL}/callbacks/usercallbacks`;
  return generalCall(formData, url, USER_CALLBACKS);
}

export function showCallbackDetails(formData) {
  const url = `${ROOT_URL}/callbacks/get`;
  return generalCall(formData, url, USER_CALLBACK);
}

export function showWithdrawals(formData) {
  const url = `${ROOT_URL}/settlements/get`;
  return generalCall(formData, url, USER_WITHDRAWALS);
}

export function listApiKeys(formData) {
  const url = `${ROOT_URL}/user/listapikeys`;
  return generalCall(formData, url, USER_API_KEY_LIST);
}

export function apiKeys(formData) {
  const url = `${ROOT_URL}/user/apikeys`;
  return generalCall(formData, url, USER_API_KEYS);
}

export function createApiKey(formData) {
  const url = `${ROOT_URL}/user/createapikey`;
  return generalCall(formData, url, USER_API_KEY_CREATE);
}

export function deactivateApiKey(formData) {
  const url = `${ROOT_URL}/user/deactivateapikey`;
  return generalCall(formData, url, USER_API_KEY_DEACTIVATE);
}

export function createPayment(formData) {
  const url = `${ROOT_URL}/pay/generate`;
  return generalCall(formData, url, CREATE_PAYMENT);
}

export function createTerminalPayment(formData) {
  const url = `${ROOT_URL}/pay/generate2`;
  return generalCall(formData, url, CREATE_TERMINAL_PAYMENT);
}

export function paymentStatus(formData) {
  const url = `${ROOT_URL}/pay/status`;
  return generalCall(formData, url, PAYMENT_STATUS);
}

export function singleInvoice(formData) {
  const url = `${ROOT_URL}/invoice/getinvoicebycode`;
  return generalCall(formData, url, SINGLE_INVOICE);
}


export function invoiceStatus(formData) {
  const url = `${ROOT_URL}/invoice/status`;
  const request = axios.post(
    url,
    querystring.stringify(formData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Inpays-Site-Language" : getCurrentLanguage()
      },
      "withCredentials": true
    }
  );
  return {
    type: INVOICE_STATUS,
    payload: request
  };

}

export function terminalInvoiceStatus(formData) {
  const url = `${ROOT_URL}/invoice/status`;
  return generalCall(formData, url, TERMINAL_INVOICE_STATUS);
}

export function userLogout(formData) {
  const url = `${ROOT_URL}/user/logout`;
  const request = axios.post(
    url,
    querystring.stringify(formData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Inpays-Site-Language" : getCurrentLanguage()
      },
      "withCredentials": true
    }
  );
  return {
    type: USER_LOGOUT,
    payload: request
  };

}

export function userLogin(formData) {
  const url = `${ROOT_URL}/user/login`;

  return (dispatch) => {
    return axios.post(
      url,
      querystring.stringify(formData), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Inpays-Site-Language" : getCurrentLanguage()

        },
        "withCredentials": true
      })
      .then(response => {
        dispatch(loginUserSuccess(response));
      })
      .catch(error => {
        //  if (error.response) {
        if (error.response.status >= 500) {
          dispatch(generalError(error.response));
        } else {
          dispatch(loginUserFailure(error.response));
        }
        /* } else {
           dispatch(generalError(error.respone));
         }*/
      })
  }
}

export function loginUserFailure(error) {
  navigateTo('/login');
  return {
    type: LOGIN_USER_FAILURE,
    payload: {
      status: error.response.status,
      statusText: error.response.statusText
    }
  }
}

export function generalError(error) {
  return {
    type: GENERAL_ERROR,
    payload: error
  }
}

export function networkProblem(error) {
  let online = window.navigator.onLine;
  if(online){
    navigateTo('/servererror');
  }
  // return {
  //   type: NETWORK_PROBLEM,
  //   offline: error
  // }
}

export function internalServerError(error) {
  navigateTo('/servererror');
}

export function loginRequired(response) {
  return (dispatch) => {
    navigateTo('/login');
    return {
      type: LOGIN_REQUIRED,
      payload: response
    }
  }
}

export function generalSuccess(response, type) {
  return {
    type: type,
    payload: response
  }
}

export function loginUserSuccess(response) {
  return {
    type: USER_LOGIN,
    payload: response
  }
}

export function generalCall(formData, url, type) {
  return (dispatch) => {
    return axios.post(
      url,
      querystring.stringify(formData), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "CSRF": localStorage.getItem('CSRF'),
          "Device-ID": localStorage.getItem('__mmapiwsid'),
          "Inpays-Site-Language" : getCurrentLanguage()
        },
        "withCredentials": true
      })
      .then(response => {
        dispatch(generalSuccess(response, type));
      })
      .catch(error => {
        try {
          if (error.message === "Network Error" || error.response.status === 500) {
            dispatch(networkProblem(error));
          }
          else if (error.response.status >= 400 && error.response.status < 500) {
            dispatch(loginRequired(error.response));
          } else {
            dispatch(generalError(error.response));
          }
        } catch (e) {
          dispatch(generalError(error));
        }
      })
  }
}

export function generalCallGet(url, type) {
  return (dispatch) => {
    return axios.get(
      url,
        {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "CSRF": localStorage.getItem('CSRF'),
          "Inpays-Site-Language" : getCurrentLanguage()
        },
        "withCredentials": true
      })
      .then(response => {
        dispatch(generalSuccess(response, type));
      })
      .catch(error => {
        try {
          if (error.message === "Network Error" || error.response.status === 500) {
            dispatch(networkProblem(error));
          }
          else if (error.response.status >= 400 && error.response.status < 500) {
            dispatch(loginRequired(error.response));
          } else {
            dispatch(generalError(error.response));
          }
        } catch (e) {
          dispatch(generalError(error));
        }
      })
  }
}

export function resetPassword(formData) {
  const url = `${ROOT_URL}/user/sendresetpasswordlink`;
  const request = axios.post(
    url,
    querystring.stringify(formData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Inpays-Site-Language" : getCurrentLanguage()
      },
      "withCredentials": true
    }
  );
  return {
    type: RESET_PASSWORD,
    payload: request
  };
}


export function userRegister(formData) {
  const url = `${ROOT_URL}/user/register`;
  const request = axios.post(
    url,
    querystring.stringify(formData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Inpays-Site-Language" : getCurrentLanguage()
      },
      "withCredentials": true
    }
  );
  return {
    type: USER_REGISTER,
    payload: request
  };

}

export function getInvoice(formData) {
  const url = `${ROOT_URL}/purchase/getinvoicefakturownia`;
  const request = axios.post(
    url,
    querystring.stringify(formData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Inpays-Site-Language" : getCurrentLanguage()
      },
      "withCredentials": true
    }
  );
  return {
    type: GET_INVOICE,
    payload: request
  };

}

export function createInvoice(formData) {
  const url = `${ROOT_URL}/purchase/createinvoicefakturownia`;
  const request = axios.post(
    url,
    querystring.stringify(formData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Inpays-Site-Language" : getCurrentLanguage()
      },
      "withCredentials": true
    }
  );
  return {
    type: CREATE_INVOICE,
    payload: request
  };

}

export function createPaymentLink(formData) {
  const url = `${ROOT_URL}/invoice/generate`;
  const request = axios.post(
    url,
    querystring.stringify(formData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "Inpays-Site-Language" : getCurrentLanguage()
      },
      "withCredentials": true
    }
  );
  return {
    type: CREATE_PAYMENT_LINK,
    payload: request
  };

}

export function ratesHistory(formData) {
  const url = `${ROOT_URL}/rates/history`;
  const request = axios.post(
    url,
    querystring.stringify(formData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "withCredentials": true
    }
  );
  return {
    type: RATES_HISTORY,
    payload: request
  };

}

export function accountStatement() {
  const url = `${ROOT_URL}/history/month`;
  const request = axios.get(url, {
    headers: {
      "Cache-Control": "no-cache,no-store,must-revalidate,max-age=-1"
    }, "withCredentials": true
  });
  return {
    type: ACCOUNT_STATEMENT,
    payload: request
  };
}

export function accountStatementCheck(formData) {
  const url = `${ROOT_URL}/history/checkmonth`;
  const request = axios.post(
    url,
    querystring.stringify(formData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "withCredentials": true
    }
  );
  return {
    type: ACCOUNT_STATEMENT_CHECK,
    payload: request
  };
}


export function changeLanguage(lang) {
  return {
    type: CHANGE_LANGUAGE,
    lang: lang
  }
}

export function sendPhoto(formData) {
  const url = `${ROOT_URL}/kyc/sendphoto`;
  const request = axios.post(
    url,
    querystring.stringify(formData), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      "withCredentials": true
    }
  );
  return {
    type: SEND_SELFIE,
    payload: request
  };
}

export function verificationStatuses(formData) {
  const url = `${ROOT_URL}/kyc/userstatuses`;
  const request = axios.post(
    url,
    querystring.stringify(formData), {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }, "withCredentials": true
  });
  return {
    type: VERIFICATION_STATUSES,
    payload: request
  };
}

export function requestList(formData) {
    const url = `${ROOT_URL}/support/requestlist`;
    const request = axios.post(
        url,
        querystring.stringify(formData), {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            "withCredentials": true
        }
    );
    return {
        type: REQUEST_LIST,
        payload: request
    };
}

export function myPurchases(formData) {
  const url = `${ROOT_URL}/purchase/mypurchases`;
  return generalCall(formData, url, USER_PURCHASES);
}

export function myTransfers(formData) {
  const url = `${ROOT_URL}/transfer/mytransfers`;
  return generalCall(formData, url, USER_TRANSFERS);
}

export function getKycList(formData) {
  const url = `${ROOT_URL}/kyc/getlistofkycdata`;
  return generalCall(formData, url, GET_KYC_LIST);
}

export function kycStatusChange(formData) {
    const url = `${ROOT_URL}/kyc/updatestatus`;
    return generalCall(formData, url, KYC_STATUS_CHANGE);
}

export function usersList(formData) {
    const url = `${ROOT_URL}/user/userslist`;
    return generalCall(formData, url, USERS_LIST);
}

export function loginToUser(formData) {
    const url = `${ROOT_URL}/user/logintouser`;
    return generalCall(formData, url, LOGIN_TO_USER);
}
export function buyTokens(formData) {
    const url = `${ROOT_URL}/purchase/personal`;
    return generalCall(formData, url, BUY_TOKENS);
}
export function getTfaCode(formData) {
    const url = `${ROOT_URL}/2fa/new`;
    return generalCall(formData, url, GET_TFA_CODE);
}
export function getTfaVerify(formData) {
    const url = `${ROOT_URL}/2fa/verify`;
    return generalCall(formData, url, GET_TFA_VERIFY);
}
export function deactivateTfa(formData) {
    const url = `${ROOT_URL}/2fa/deactivate`;
    return generalCall(formData, url, DEACTIVATE_TFA);
}
export function tokenAskCalculate(formData) {
    const url = `${ROOT_URL}/rates/personalcalculate`;
    return generalCall(formData, url, TOKEN_ASK_CALCULATE)
}
export function countryList(formData) {
    const url = `${ROOT_URL}/util/getcountrylist`;
    return generalCall(formData, url, COUNTRY_LIST)
}
export function getNotifications(formData) {
    const url = `${ROOT_URL}/notification/getnotifications`;
    return generalCall(formData, url, GET_NOTIFICATIONS)
}
export function getLatestTransactions(formData) {
  const url = `${ROOT_URL}/notification/getlatesttransactions`;
  return generalCall(formData, url, GET_LATEST_TRANSACTIONS)
}
export function getDevelopmentNews(formData) {
  const url = `${ROOT_URL}/notification/developmentnews`;
  return generalCall(formData, url, GET_DEVELOPMENT_NEWS)
}
export function changeInvoiceCrypto(formData) {
    const url = `${ROOT_URL}/invoice/changeinvoicecrypto`;
    return generalCall(formData, url, CHANGE_INVOICE_CRYPTO)
}

export function voucherCreate(formData) {
    const url = `${ROOT_URL}/voucher/create`;
    return generalCall(formData, url, VOUCHER_CREATE)
}

export function voucherStatus(formData) {
    const url = `${ROOT_URL}/voucher/status`;
    return generalCall(formData, url, VOUCHER_STATUS)
}

export function voucherWithdraw(formData) {
    const url = `${ROOT_URL}/voucher/withdraw`;
    return generalCall(formData, url, VOUCHER_WITHDRAW)
}

export function voucherValidateWithdraw(formData) {
  const url = `${ROOT_URL}/voucher/validatewithdraw`;
  return generalCall(formData, url, VOUCHER_VALIDATE_WITHDRAW)
}

export function validatePinWithdraw(formData) {
  const url = `${ROOT_URL}/voucher/validatepin`;
  return generalCall(formData, url, VALIDATE_PIN_WITHDRAW)
}

export function getKycStatus(formData) {
  const url = `${ROOT_URL}/kyc/status`;
  return generalCall(formData, url, KYC_STATUS);
}

export function acceptMigration(formData) {
  const url = `${ROOT_URL}/user/acceptmigration`;
  return generalCall(formData, url, ACCEPT_MIGRATION);
}

export function acceptTerms(formData) {
  const url = `${ROOT_URL}/user/acceptterms`;
  return generalCall(formData, url, ACCEPT_TERMS);
}
export function verifyEmail(formData) {
  const url = `${ROOT_URL}/user/verifymail`;
  return generalCall(formData, url, VERIFY_EMAIL);
}

export function updateOptionalKyc(formData) {
  const url = `${ROOT_URL}/kyc/updateoptional`;
  return generalCall(formData, url, KYC_UPDATE_OPTIONAL);
}

export function resetKyc(formData) {
  const url = `${ROOT_URL}/kyc/resetkyc`;
  return generalCall(formData, url, KYC_RESET);
}

export function addKycExpirationDate(formData) {
  const url = `${ROOT_URL}/kyc/addexpirationdate`;
  return generalCall(formData, url, KYC_ADD_EXPIRATION_DATE);
}

export function rushPayPayment(code) {
  const url = `${ROOT_URL}/rushpay/payment/${code}`;
  return generalCallGet(url, RUSHPAY_PAYMENT);
}

export function fiberpayPayment(formData) {
  const url = `${ROOT_URL}/fiberpay/payment`;
  return generalCall(formData, url, FIBERPAY_PAYMENT);
}

export function createVerificationOrder() {
  const url = `${ROOT_URL}/verification/create`;
  return generalCall({}, url, CREATE_VERIFICATION_ORDER);
}

export function userCheckPassword(formData) {
  const url = `${ROOT_URL}/user/checkpassword`;
  return generalCall(formData, url, USER_CHECK_PASSWORD);
}

export function confirmVideoverification(formData) {
  const url = `${ROOT_URL}/kyc/videoverification`;
  return generalCall(formData, url, CONFIRM_VIDEOVERIFICATION);
}

export function getCommissionsTable(formData) {
  const url = `${ROOT_URL}/util/getcommissions`;
  return generalCall(formData, url, GET_COMMISSIONS_TABLE);
}

export function getVerifiedBankAccounts(formData) {
  const url = `${ROOT_URL}/bank-accounts`;
  return generalCall(formData, url, GET_VERIFIED_BANK_ACCOUNTS);
}

export function createBankAccountsVerificationOrder() {
  const url = `${ROOT_URL}/bank-accounts/verification/createorder`;
  return generalCall(null, url, CREATE_BANK_ACCOUNTS_VERIFICATION_ORDER);
}

export function getBankAccountsVerificationOrders() {
  const url = `${ROOT_URL}/bank-accounts/verification/order`;
  return generalCall(null, url, GET_BANK_ACCOUNTS_VERIFICATION_ORDERS);
}
