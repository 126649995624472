import {LOGIN, USER_GET} from '../actions/index';

export default function (state = null, action) {

  switch (action.type) {
    case LOGIN:
    case USER_GET:
      if (typeof action.payload.data === 'undefined') {
        return state;
      }
      return action.payload.data;
    default:
      break;
  }
  return state;
}
