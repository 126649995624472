import React from 'react';
import store from './store';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import history from './history';
import asyncComponent from "./components/AsyncComponent";
import ReactGA from 'react-ga4';

import { Redirect } from "react-router";
import { navigateTo } from './helpers/languagerouter';
//import GitInfo from "react-git-info/macro";
import './App.css';

const Panel = asyncComponent(() => import("./panel"));
const Terminal = asyncComponent(() => import("./components/terminal/terminal"));
const App = asyncComponent(() => import("./App"));
const NoCookies = asyncComponent(() => import("./components/nocookies"));



function logPageView() {
  // console.log(window.location.pathname + window.location.search);
  if (typeof ReactGA.initialize === 'function') {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }
}

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/service-worker.js', {scope: '/'}).then(function(registration) {
//     console.log('Service worker registered successfully');
//   }).catch(function(err) {
//     console.log('Service worker registration failed: ', err);
//   });
// }



const Routes = () => {
  if (navigator.cookieEnabled) {
    return (
      <Router history={history} onUpdate={logPageView}>
        <Switch>
          <Route path='/nocookies' render={() => navigateTo('/')} />
          <Route path="/terminal" component={Terminal} />
          <Route path="/:lang(en|pl|de|it|fr|ru)/terminal" component={Terminal} />
          <Route path="/panel" component={Panel} />
          <Route path="/:lang(en|pl|de|it|fr|ru)/panel" component={Panel} />
          <Route path="/:lang(en|pl|de|it|fr|ru)" component={App} />
          <Route component={App} />
        </Switch>
      </Router>
    );
  } else {
    return (
      <Router history={history} onUpdate={logPageView}>
        <Switch>
          <Route path={"/nocookies"} component={NoCookies} />
          <Redirect to='/nocookies' />
        </Switch>
      </Router>
    )
  }
};

const FullApp = (t) => (
  <>
  {/*
    <div id="gitInfo" style={{display: 'none'}}>
      {GitInfo().commit.shortHash}
    </div>
  */}
    <Provider store={store}>
      <Routes />
    </Provider>
  </>
);

if (typeof ReactGA.initialize === 'function') {
  ReactGA.initialize('G-4D5JXZP1SE');

  ReactGA.ga((tracker) => {
    let clientId = tracker.get('clientId');
    localStorage.setItem('gaClientId', clientId);
  });
}

export default FullApp