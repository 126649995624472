import {CREATE_PURCHASE} from '../actions/index';

export default function (state = null, action) {
  switch (action.type) {
    case CREATE_PURCHASE:
      return action.payload.data;
    default:
      break;
  }
  return state;
}
