import * as React from "react"
import i18n from "i18next";
import history from "../history";
import { Link as RouterLink, Redirect as RouterRedirect } from 'react-router-dom';
import locale2 from "locale2";
import { Flag } from "semantic-ui-react";
import "../css/components/flag.css";


const supportedLanguages = ['pl', 'en'];

/*
export const countryDropdownOptions = [
  {key: 'gb', value: 'en', text: <Flag name='gb'/>},
  {key: 'pl', value: 'pl', text: <Flag name='pl'/>},
  {key: 'de', value: 'de', text: <Flag name='de'/>},
  {key: 'it', value: 'it', text: <Flag name='it'/>},
  {key: 'fr', value: 'fr', text: <Flag name='fr'/>},
  {key: 'ru', value: 'ru', text: <Flag name='ru'/>}
];*/

export const countryDropdownOptions = [
  {key: 'gb', value: 'en', text: <Flag name='gb'/>},
  {key: 'pl', value: 'pl', text: <Flag name='pl'/>}  
];

const bindings = {
  siteName: process.env.REACT_APP_SITE_NAME ? process.env.REACT_APP_SITE_NAME : 'InPay',
  siteFullName: process.env.REACT_APP_SITE_FULL_NAME ? process.env.REACT_APP_SITE_FULL_NAME : 'InPay.To LTD',
  siteUrl: process.env.REACT_APP_SITE_URL ? process.env.REACT_APP_SITE_URL : 'inpay.pl',
  escapedFullName: process.env.REACT_APP_ESCAPED_FULL_NAME ? process.env.REACT_APP_ESCAPED_FULL_NAME : 'InPay.To LTD',
};

function getLanguagePath(path) {
  const lang = i18n.language;
  return '/' + lang + path;
}

function getLocalLanguage() {
  let localLang = localStorage.getItem('sitesLanguage');
  if (localLang) {
    return localLang
  } else {
    let lang = locale2.substr(0, 2);
    if (supportedLanguages.includes(lang))
      return lang;
    else
      return 'en';
  }
}

export function mountComponentLanguage(props) {
  let url = props.location.pathname;
  if (props.match.params.lang) {
    url = url.substr(3);
    if (props.match.params.lang !== i18n.language){
      changeLanguage(props.match.params.lang);
    }
  } else {
    let lang = getLocalLanguage();
    if (lang !== i18n.language) {
      localStorage.setItem('sitesLanguage', lang);
      changeLanguage(lang);
    }
    navigateTo(url);
  }
}

export function navigateTo(path) {
  let langPath = path;
  langPath = getLanguagePath(langPath)
  history.push(langPath);
}

export class Link extends React.Component {
  render() {
    let path = getLanguagePath(this.props.to)
    if (this.props.noLang) {
      path = this.props.to;
    }
    return (
      <RouterLink
        className={this.props.className}
        to={path}
        replace={this.props.replace}
        onClick={this.props.onClick}
        target={this.props.target}
        style={this.props.style}
      >
        <span>{this.props.children}</span>
      </RouterLink>
    )
  }
}

export class Redirect extends React.Component {
  render() {
    return <RouterRedirect to={getLanguagePath(this.props.to)} />;
  }
}

export function changeSiteLanguage(lang, path, params = null) {
  if (i18n.language !== lang) {
    changeLanguage(lang);
    localStorage.setItem('sitesLanguage', lang);
    let url = path;
    if (params) {
      url = url.substr(3);
    }
    navigateTo(url);
  }
}

export function changeLanguage(lang) {
  i18n.changeLanguage(lang);
}

export function getLastElementInPath(path) {
  let splitPath = path.split('/');
  let element = '/';
  if (splitPath.length > 1)
    element = splitPath[splitPath.length - 1];
  return element;
}

export function getCurrentLanguage() {
  return i18n.language;
}

export function getRouterActiveItem(props) {
  return props.location.pathname.split('/')[props.match.url.split('/').length]
}

export function t(word) {
  return i18n.t(word, bindings)
}