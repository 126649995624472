import {combineReducers} from 'redux';
import UserReducer from './reducer_user';
import BidsReducer from './reducer_bids';
import AsksReducer from './reducer_asks';
import CreatePurchase from './reducer_create_purchase';
import PurchaseStatus from './reducer_purchasestatus';
import PurchaseDecision from './reducer_purchasedecision';
import CreateTransfer from './reducer_createtransfer';
import CreateTransferAtm from './reducer_createtransferatm';
import AskCalculate from './reducer_askcalculate';
import {
  TRANSFER_STATUS,
  PARTNER_GET,
  BID_CALCULATE,
  GET_DEVELOPMENT_NEWS,
  GET_LATEST_TRANSACTIONS,
  TERMINAL_INVOICE_STATUS,
  PARTNER_UPDATE,
  PASSWORD_UPDATE,
  USER_BALANCES,
  USER_INVOICES,
  USER_CALLBACKS,
  USER_CALLBACK,
  USER_API_KEYS,
  CREATE_PAYMENT,
  PAYMENT_STATUS,
  SINGLE_INVOICE,
  INVOICE_STATUS,
  USER_LOGOUT,
  USER_LOGIN,
  USER_DELETE,
  USER_WITHDRAWALS,
  RESET_PASSWORD,
  USER_REGISTER,
  CHANGE_LANGUAGE,
  GET_INVOICE,
  CREATE_INVOICE,
  USER_API_KEY_LIST,
  USER_API_KEY_CREATE,
  USER_API_KEY_DEACTIVATE,
  CREATE_PAYMENT_LINK,
  RATES_HISTORY,
  ACCOUNT_STATEMENT,
  ACCOUNT_STATEMENT_CHECK,
  NETWORK_PROBLEM,
  GENERAL_ERROR,
  LOGIN_REQUIRED,
  GET_USER_BY_HASH,
  SET_PASSWORD,
  PURCHASE_LIMITS,
  SEND_SELFIE,
  CHANGE_STATUS,
  GET_OTHER_TRANSACTIONS,
  SUPPORT_PURCHASE_STATUS,
  REQUEST_LIST,
  VERIFICATION_STATUSES,
  USER_PURCHASES,
  USER_TRANSFERS,
  GET_KYC_LIST,
  KYC_STATUS_CHANGE,
  USERS_LIST,
  LOGIN_TO_USER,
  BUY_TOKENS,
  GET_TFA_CODE,
  GET_TFA_VERIFY,
  DEACTIVATE_TFA,
  TOKEN_ASK_CALCULATE,
  GET_NOTIFICATIONS,
  COUNTRY_LIST,
  CREATE_TERMINAL_PAYMENT,
  CHANGE_INVOICE_CRYPTO,
  VOUCHER_CREATE,
  VOUCHER_STATUS,
  VOUCHER_WITHDRAW,
  VOUCHER_VALIDATE_WITHDRAW,
  VALIDATE_PIN_WITHDRAW,
  KYC_STATUS,
  ACCEPT_MIGRATION,
  ACCEPT_TERMS,
  VERIFY_EMAIL,
  KYC_UPDATE_OPTIONAL,
  KYC_RESET,
  KYC_ADD_EXPIRATION_DATE,
  RUSHPAY_PAYMENT,
  FIBERPAY_PAYMENT,
  CREATE_VERIFICATION_ORDER,
  CONFIRM_VIDEOVERIFICATION,
  USER_CHECK_PASSWORD,
  GET_COMMISSIONS_TABLE,
  GET_VERIFIED_BANK_ACCOUNTS,
  CREATE_BANK_ACCOUNTS_VERIFICATION_ORDER, GET_BANK_ACCOUNTS_VERIFICATION_ORDERS,
} from '../actions/index';

export function bidCalculate(state = null, action) {
  switch (action.type) {
    case BID_CALCULATE:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function transferStatus(state = null, action) {
  switch (action.type) {
    case TRANSFER_STATUS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function partnerGet(state = null, action) {
  switch (action.type) {
    case PARTNER_GET:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function partnerUpdate(state = null, action) {
  switch (action.type) {
    case PARTNER_UPDATE:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function updatePassword(state = null, action) {
  switch (action.type) {
    case PASSWORD_UPDATE:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function balanceGet(state = null, action) {
  switch (action.type) {
    case USER_BALANCES:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function showInvoices(state = null, action) {
  switch (action.type) {
    case USER_INVOICES:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function getOtherTransactions(state = null, action) {
  switch (action.type) {
    case GET_OTHER_TRANSACTIONS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function supportPurchaseStatus(state = null, action) {
    switch (action.type) {
        case SUPPORT_PURCHASE_STATUS:
            return action.payload.data;
        default:
            break;
    }
    return state;
}
export function changeStatus(state = null, action) {
    switch (action.type) {
        case CHANGE_STATUS:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function showCallbacks(state = null, action) {
  switch (action.type) {
    case USER_CALLBACKS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function showCallbackDetails(state = null, action) {
  switch (action.type) {
    case USER_CALLBACK:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function showWithdrawals(state = null, action) {
  switch (action.type) {
    case USER_WITHDRAWALS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function listApiKeys(state = null, action) {
  switch (action.type) {
    case USER_API_KEY_LIST:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function apiKeys(state = null, action) {
  switch (action.type) {
    case USER_API_KEYS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function createApiKey(state = null, action) {
  switch (action.type) {
    case USER_API_KEY_CREATE:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function deactivateApiKey(state = null, action) {
  switch (action.type) {
    case USER_API_KEY_DEACTIVATE:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function createPayment(state = null, action) {
  switch (action.type) {
    case CREATE_PAYMENT:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function paymentStatus(state = null, action) {
  switch (action.type) {
    case PAYMENT_STATUS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function singleInvoice(state = null, action) {
  switch (action.type) {
    case SINGLE_INVOICE:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function invoiceStatus(state = null, action) {
  switch (action.type) {
    case INVOICE_STATUS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function userLogout(state = null, action) {
  switch (action.type) {
    case USER_LOGOUT:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function userLogin(state = null, action) {
  switch (action.type) {
    case USER_LOGIN:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function resetPassword(state = null, action) {
  switch (action.type) {
    case RESET_PASSWORD:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function getUserByHash(state = null, action) {
  switch (action.type) {
    case GET_USER_BY_HASH:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function setPassword(state = null, action) {
  switch (action.type) {
    case SET_PASSWORD:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function userRegister(state = null, action) {
  switch (action.type) {
    case USER_REGISTER:
      return action.payload.data;
    default:
      break;
  }
  return state;
}


export function changeLanguage(state = null, action) {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return action.lang;
    default:
      break;
  }
  return state;
}

export function getInvoice(state = null, action) {
  switch (action.type) {
    case GET_INVOICE:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function createInvoice(state = null, action) {
  switch (action.type) {
    case CREATE_INVOICE:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function createPaymentLink(state = null, action) {
  switch (action.type) {
    case CREATE_PAYMENT_LINK:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function ratesHistory(state = null, action) {
  switch (action.type) {
    case RATES_HISTORY:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function accountStatement(state = null, action) {
  switch (action.type) {
    case ACCOUNT_STATEMENT:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function accountStatementCheck(state = null, action) {
  switch (action.type) {
    case ACCOUNT_STATEMENT_CHECK:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function generalError(state = null, action) {
  switch (action.type) {
    case GENERAL_ERROR:
      let payload = action.payload;
      payload.time = Date.now();
      return payload;
    default:
      break;
  }
  return state;
}

export function networkProblem(state = null, action) {
  switch (action.type) {
    case NETWORK_PROBLEM:
      return action.offline;
    default:
      break;
  }
  return state;
}

export function loginRequired(state = null, action) {
  switch (action.type) {
    case LOGIN_REQUIRED:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function purchaseLimits(state = null, action) {
  switch (action.type) {
    case PURCHASE_LIMITS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function sendPhoto(state = null, action) {
  switch (action.type) {
    case SEND_SELFIE:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function requestList(state = null, action) {
  switch (action.type) {
    case REQUEST_LIST:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function verificationStatuses(state = null, action) {
  switch (action.type) {
    case VERIFICATION_STATUSES:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function myPurchases(state = null, action) {
  switch (action.type) {
    case USER_PURCHASES:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function myTransfers(state = null, action) {
  switch (action.type) {
    case USER_TRANSFERS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function usersList(state = null, action) {
    switch (action.type) {
        case USERS_LIST:
            return action.payload.data;
        default:
            break;
    }
    return state;
}
export function loginToUser(state = null, action) {
    switch (action.type) {
        case LOGIN_TO_USER:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function getKycList(state = null, action) {
    switch (action.type) {
        case GET_KYC_LIST:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function kycStatusChange(state = null, action) {
    switch (action.type) {
        case KYC_STATUS_CHANGE:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function buyTokens(state = null, action) {
    switch (action.type) {
        case BUY_TOKENS:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function getTfaCode(state = null, action) {
    switch (action.type) {
        case GET_TFA_CODE:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function getTfaVerify(state = null, action) {
    switch (action.type) {
        case GET_TFA_VERIFY:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function deactivateTfa(state = null, action) {
    switch (action.type) {
        case DEACTIVATE_TFA:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function tokenAskCalculate(state = null, action) {
    switch (action.type) {
        case TOKEN_ASK_CALCULATE:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function countryList(state = null, action) {
    switch (action.type) {
        case COUNTRY_LIST:
            return action.payload.data;
        default:
            break;
    }
    return state;
}
export function getNotifications(state = null, action) {
    switch (action.type) {
        case GET_NOTIFICATIONS:
            return action.payload.data;
        default:
            break;
    }
    return state;
}



export function createTerminalPayment(state = null, action) {
  switch (action.type) {
    case CREATE_TERMINAL_PAYMENT:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function terminalInvoiceStatus(state = null, action) {
  switch (action.type) {
    case TERMINAL_INVOICE_STATUS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function getLatestTransactions(state = null, action) {
  switch (action.type) {
    case GET_LATEST_TRANSACTIONS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function getDevelopmentNews(state = null, action) {
  switch (action.type) {
    case GET_DEVELOPMENT_NEWS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function changeInvoiceCrypto(state = null, action) {
    switch (action.type) {
        case CHANGE_INVOICE_CRYPTO:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function voucherCreate(state = null, action) {
    switch (action.type) {
        case VOUCHER_CREATE:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function voucherStatus(state = null, action) {
    switch (action.type) {
        case VOUCHER_STATUS:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function voucherWithdraw(state = null, action) {
    switch (action.type) {
        case VOUCHER_WITHDRAW:
            return action.payload.data;
        default:
            break;
    }
    return state;
}

export function voucherValidateWithdraw(state = null, action) {
  switch (action.type) {
    case VOUCHER_VALIDATE_WITHDRAW:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function validatePinWithdraw(state = null, action) {
  switch (action.type) {
    case VALIDATE_PIN_WITHDRAW:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function userDelete(state = null, action) {
  switch (action.type) {
    case USER_DELETE:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function getKycStatus(state = null, action) {
  switch (action.type) {
    case KYC_STATUS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function acceptMigration(state = null, action) {
  switch (action.type) {
    case ACCEPT_MIGRATION:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function acceptTerms(state = null, action) {
  switch (action.type) {
    case ACCEPT_TERMS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function verifyEmail(state = null, action) {
  switch (action.type) {
    case VERIFY_EMAIL:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function updateOptionalKyc(state = null, action) {
  switch (action.type) {
    case KYC_UPDATE_OPTIONAL:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function resetKyc(state = null, action) {
  switch (action.type) {
    case KYC_RESET:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function addKycExpirationDate(state = null, action) {
  switch (action.type) {
    case KYC_ADD_EXPIRATION_DATE:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function rushPayPayment(state = null, action) {
  switch (action.type) {
    case RUSHPAY_PAYMENT:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function fiberpayPayment(state = null, action) {
  switch (action.type) {
    case FIBERPAY_PAYMENT:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function createVerificationOrder(state = null, action) {
  switch (action.type) {
    case CREATE_VERIFICATION_ORDER:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function userCheckPassword(state = null, action) {
  switch (action.type) {
    case USER_CHECK_PASSWORD:
      return action.payload.data;
    default:
      break;
  }
  return state;
}



export function confirmVideoverification(state = null, action) {
  switch (action.type) {
    case CONFIRM_VIDEOVERIFICATION:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function getCommissionsTable(state = null, action) {
  switch (action.type) {
    case GET_COMMISSIONS_TABLE:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function getVerifiedBankAccounts(state = null, action) {
  switch (action.type) {
    case GET_VERIFIED_BANK_ACCOUNTS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function createBankAccountsVerificationOrder(state = null, action) {
  switch (action.type) {
    case CREATE_BANK_ACCOUNTS_VERIFICATION_ORDER:
      return action.payload.data;
    default:
      break;
  }
  return state;
}

export function getBankAccountsVerificationOrders(state = null, action) {
  switch (action.type) {
    case GET_BANK_ACCOUNTS_VERIFICATION_ORDERS:
      return action.payload.data;
    default:
      break;
  }
  return state;
}


const rootReducer = combineReducers({
  user: UserReducer,
  bids: BidsReducer,
  asks: AsksReducer,
  bidCalculate: bidCalculate,
  createPurchase: CreatePurchase,
  purchaseStatus: PurchaseStatus,
  purchaseDecision: PurchaseDecision,
  createTransfer: CreateTransfer,
  createTransferAtm: CreateTransferAtm,
  askCalculate: AskCalculate,
  transferStatus: transferStatus,
  partnerGet: partnerGet,
  partnerUpdate: partnerUpdate,
  updatePassword: updatePassword,
  balanceGet: balanceGet,
  showInvoices: showInvoices,
  changeStatus: changeStatus,
  getOtherTransactions: getOtherTransactions,
  supportPurchaseStatus: supportPurchaseStatus,
  showCallbacks: showCallbacks,
  showCallbackDetails: showCallbackDetails,
  listApiKeys: listApiKeys,
  apiKeys: apiKeys,
  createApiKey: createApiKey,
  deactivateApiKey: deactivateApiKey,
  createPayment: createPayment,
  paymentStatus: paymentStatus,
  singleInvoice: singleInvoice,
  invoiceStatus: invoiceStatus,
  userLogout: userLogout,
  userLogin: userLogin,
  userDelete: userDelete,
  showWithdrawals: showWithdrawals,
  resetPassword: resetPassword,
  userRegister: userRegister,
  changeLanguage: changeLanguage,
  getInvoice: getInvoice,
  createInvoice: createInvoice,
  createPaymentLink: createPaymentLink,
  ratesHistory: ratesHistory,
  accountStatement: accountStatement,
  accountStatementCheck: accountStatementCheck,
  generalError: generalError,
  networkProblem: networkProblem,
  loginRequired: loginRequired,
  getUserByHash: getUserByHash,
  setPassword: setPassword,
  purchaseLimits: purchaseLimits,
  sendPhoto: sendPhoto,
  verificationStatuses: verificationStatuses,
  requestList: requestList,
  myPurchases: myPurchases,
  myTransfers: myTransfers,
  usersList: usersList,
  loginToUser: loginToUser,
  getKycList: getKycList,
  kycStatusChange: kycStatusChange,
  getTfaCode: getTfaCode,
  getTfaVerify: getTfaVerify,
  deactivateTfa: deactivateTfa,
  buyTokens: buyTokens,
  tokenAskCalculate: tokenAskCalculate,
  countryList: countryList,
  getNotifications: getNotifications,
  createTerminalPayment: createTerminalPayment,
  terminalInvoiceStatus: terminalInvoiceStatus,
  getLatestTransactions: getLatestTransactions,
  getDevelopmentNews: getDevelopmentNews,
  changeInvoiceCrypto: changeInvoiceCrypto,
  voucherCreate: voucherCreate,
  voucherStatus: voucherStatus,
  voucherWithdraw: voucherWithdraw,
  voucherValidateWithdraw: voucherValidateWithdraw,
  validatePinWithdraw: validatePinWithdraw,
  getKycStatus: getKycStatus,
  acceptMigration : acceptMigration,
  acceptTerms : acceptTerms,
  verifyEmail: verifyEmail,
  updateOptionalKyc: updateOptionalKyc,
  resetKyc: resetKyc,
  addKycExpirationDate: addKycExpirationDate,
  rushPayPayment: rushPayPayment,
  fiberpayPayment: fiberpayPayment,
  createVerificationOrder: createVerificationOrder,
  userCheckPassword: userCheckPassword,
  confirmVideoverification: confirmVideoverification,
  getCommissionsTable: getCommissionsTable,
  getVerifiedBankAccounts,
  createBankAccountsVerificationOrder,
  getBankAccountsVerificationOrders,
});

export default rootReducer;
